<template>
  <div class="content-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

     <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <p>{{ information }}</p>
      </div>
    </vue-final-modal>

    <vue-final-modal v-model="showImageModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showImageModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <img v-bind:src="imageBase64"/>
      </div>
    </vue-final-modal>

    <div class="survey-questions__list">
      <div class="survey-question" v-for="(question, index) of questions" v-bind:id="question.id" v-bind:key="question.id" v-bind:class="{ 'requires-response': question.requiresResponse }" >
        <h3 class="title">
          <span>{{ index + 1 }}. {{ question.title }} <small v-if="question.multipleResponses">(Multiple Answers Possible)</small></span>  
          <button class="btn-info" v-if="question.imageBase64 != null && question.imageBase64 != ''" v-on:click="showImage(question.imageBase64)">
            <i class="fas fa-camera-retro"></i>
          </button>
          <button class="btn-info"  v-on:click="showInformation(question)" v-if="question.information != null && question.information != ''">
            <i class="fas fa-info-circle"></i>
          </button>
        </h3>

        <div class="responses-list">
          <div class="response__block" v-for="response of question.responses.sort((a, b) => a.position - b.position)" v-bind:key="response.id">
            <button class="response" v-bind:class="{ 'response-selected': response.isSelected == true}">
              <span v-on:click="selectResponse(question, response)">{{ response.response }}</span>
              <!-- <div class="check"><i v-if="response.isSelected == true" class="fas fa-check"></i></div> -->
              <button class="btn-info" v-on:click="showImage(response.imageBase64)">
                <i v-if="response.imageBase64 != null && response.imageBase64 != ''"  class="fas fa-camera-retro"></i>
              </button>
              <button class="btn-info" v-if="response.information != null && response.information != ''" v-on:click="showInformation(response)">
                <i class="fas fa-info-circle"></i>
              </button>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="action-buttons">
      <button class="btn" v-on:click="finish()">Finish Assessment</button>

      <button class="btn-accent btn-back" v-on:click="back()">Back</button>
      <!-- <router-link class="btn-accent" to="/screening">Back</router-link> -->
    </div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
      isLoading: false,
      questions: [],

      information: "",
      imageBase64: "",

      showModal: false,
      showImageModal: false,
    }
  },

  mounted() {
    var storedQuestionsJson = localStorage.getItem("@screening-questions");
    if (storedQuestionsJson == null) {
      this.$router.push("/");  
      return;
    }

    this.loadQuestions(JSON.parse(storedQuestionsJson));
  },

  methods: {
    back() {
      if (confirm("Are you sure you want to go back? This will allow you to edit the current assessment.\n\nIf you would like to start a new case assessment, please select ‘Finish Assessment’.")) {
        this.$router.push("/screening");
      }
    },
    finish() {
      for (let question of this.questions) {
        question.requiresResponse = false;
        var responseCount = question.responses.filter(r => r.isSelected == true).length;
        if (responseCount == 0) {
          question.requiresResponse = true;
          const el = document.getElementById(question.id);
          if (el) {
            el.scrollIntoView({behavior: 'smooth'});
          }
          return;
        }
      }

      this.$router.push("/summary");
    },

    loadQuestions(screeningQuestions) {
      this.isLoading = true;
      var selectedServiceQuestionIds = screeningQuestions.filter(s => s.response == true).map(s => s.id);

      var dto = {
        selectedServiceQuestionIds: selectedServiceQuestionIds
      }

      this.axios.post("/api/question/fetch", dto).then((response) => {
        this.isLoading = false;
        this.questions = response.data;

        var storedQuestionsJson = localStorage.getItem("@survey-questions");
        if (storedQuestionsJson != null) {
          this.updateFromStoredQuestions(JSON.parse(storedQuestionsJson));
        }
      });
    },

     updateFromStoredQuestions(storedQuestions) {
        for (let question of this.questions) {
          var storedQuestion = storedQuestions.find(q => q.id == question.id);
          if (storedQuestion == null)
            continue;

          for (let response of question.responses) {
            var storedResponse = storedQuestion.responses.find(r => r.id == response.id);
            if (storedResponse == null)
              continue;

            response.isSelected = storedResponse.isSelected;
          }
        }
    },

    showInformation(question) {
      this.information = question.information;
      this.showModal = true;
    },

    selectResponse(question, response) {
      if (!question.multipleResponses) {
        for (let questionResponse of question.responses) {
          questionResponse.isSelected = false;
        }
      }

      if (response.isSelected == null)
        response.isSelected = false;

      response.isSelected = !response.isSelected;
      localStorage.setItem("@survey-questions", JSON.stringify(this.questions))
    },

    showImage(imageBase64) {
      this.imageBase64 = imageBase64;
      this.showImageModal = true;
    },
  },

  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  @import "../../breakpoints.scss";

  .content-wrapper {
    .survey-questions__list {
      margin-top: 20px;
      .requires-response {
        border-left: 2px solid red;
        border-right: 2px solid red;

        .title {
          background: red !important;
        }
      }

      .survey-question {
        margin-bottom: 10px;
        border-bottom: 1px solid #013B82;
        padding-bottom: 5px;

        .title {
          padding: 10px;
          background: #000;
          color: #FFF;
          font-weight: bold;

          display: flex;
          align-items: center;

          small {
            font-size: 11px;
          }

          span {
            flex: 1;
            text-align: left;
          }

          .btn-info {
            color: #FFF;
            outline: 0;
            background: transparent;
            border: 0;
          }
        }
      }

      .responses-list {
        padding-top: 5px;

        .response__block {
          border-bottom: 1px solid #cecece;
           &:last-child {
            border-bottom: 0px;
          }

          .response-selected {
            background: rgba(0, 63, 140, 0.9) !important;
            //padding-left: 10px !important;
            // padding-right: 10px !important;
            color: #FFF !important;
            border-radius: 5px !important;

            span {
              color: #FFF !important;
            }

            .btn-info {
              color: #FFF !important;
            }
          }

          .response {
            width: 100%;
            border: 0px;
            outline: 0px;
            background: transparent;
            text-align: left;
            // padding: 8px 0px;
            // padding-left: 5px;
            // padding-right: 5px;

            display: flex;
            align-items: center;
            color: #000;

            span {
              flex: 1;
              color: #000;
              padding: 8px 5px;
            }

            .check {
              padding-right: 5px;
              // color: #013B82;
              color: #FFF;

              width: 15px;
            }

            .btn-info {
              background: transparent;
              border: 0;
              outline: 0;
              width: 25px;
              text-align: center;
            }
          }
        }
      }
    }
  }
    

  .action-buttons {
    a, button {
      margin-bottom: 10px;
    }
  }

  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;

    img {
      width: 100%;

      @include md {
        width: 80%;
      }
    }
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }

  .btn-back {
    margin-top: 10px;
    cursor: pointer;
  }
</style>
