<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <loading v-model:active="isLoading" :is-full-page="true"/>

      <img class="uni-logo" src="/assets/logo.png"/>

      <h1>Reset Password</h1>

      <form v-on:submit.prevent="onSubmit">
        <div class="form-group">
          <label>Old Password</label>
          <input type="password" v-model="oldPassword"/>
        </div>

        <div class="form-group">
          <label>New Password</label>
          <input type="password" v-model="newPassword"/>
        </div>

        <div class="form-group">
          <label>Confirm Password</label>
          <input type="password" v-model="confirmPassword"/>
        </div>

        <button type="submit" class="btn">Reset</button>
      </form>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";
import authStore from "../store/auth-store";

export default {
   setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    }
  },

  methods: {
    async onSubmit() {
      if (this.oldPassword == "" || this.newPassword == "" || this.confirmPassword == "") {
        this.toast.error("Enter passwords");
        return; 
      }

      if (this.newPassword != this.confirmPassword) {
        this.toast.error("Passwords do not match");
        return; 
      }


      this.isLoading = true;

      var dto = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      };

      try {
        var response = await this.axios.post("/api/auth/changepassword", dto, { headers: { 'Authorization' : 'Bearer ' + this.user.token }});
        console.log(response);
        this.isLoading = false;
        this.toast.success("Successfully reset password");
        this.$router.push("/");

      } catch (e) {
        //this.$toast.open('Invalid username/password');
        this.toast.error("Invalid old password");
        this.isLoading = false;
      }
    }
  },

  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    padding: 10px;
    img {
      max-width: 90%;
      margin: 15px auto;
    }

    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      line-height: 36px;
    }

    form {
      margin-top: 20px;
      .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        label {
          font-weight: bold;
          text-align: left;
          margin-bottom: 5px;
        }

        input {
          padding: 5px 8px;
        }
      }
    }
  }
</style>
