<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <img class="uni-logo" src="/assets/logo.png"/>

      <h1>Endodontic Complexity Assessment Tool</h1>

      <!-- <router-link v-if="showBegin" class="btn" to="demographic">{{ beginTitle }}</router-link> -->

      <!-- <button v-if="showBegin && resume" class="btn-accent" v-on:click="newAssessment()">Start New Assessment</button> -->

      <div v-if="showBegin" class="content-block">
        <p v-if="isLoggedIn() == false">
          Create an account if you wish to save your complexity assessments to review again. 
          Registering as a user would also contribute to our ongoing research in the field of endodontics, 
          as well as our efforts to improve access to endodontic services in the future.
        </p>

        <div class="action-buttons" v-if="isLoggedIn() == false">
          <div class="btn-group">
            <button class="btn" v-bind:class="{ 'btn-accent': resume }" v-on:click="login()">Login</button>
            <button class="btn" v-bind:class="{ 'btn-accent': resume }" v-on:click="newAssessment()">Register</button>
          </div>

          <button class="btn btn-accent" v-if="!resume" v-on:click="continueAsGuest()">Continue As Guest</button>
          <button class="btn btn-accent" v-if="resume" v-on:click="continueAsGuest()">Start New Assessment</button>
          <button class="btn" v-if="resume" v-on:click="resumeAssessment()">Resume Assessment</button>
        </div>

        <div class="action-buttons" v-if="isLoggedIn() == true">          
          <button class="btn" v-bind:class="{ 'btn-accent': resume }" v-on:click="newAssessment()">Start New Assessment</button>
          <button class="btn" v-if="resume" v-on:click="resumeAssessment()">Resume Assessment</button>
        </div>
      </div>

      <router-view></router-view>

      <div class="external-links-block" v-if="showBegin">
        <a href="https://www.e-s-e.eu/" target="_blank" class="ese-logo">
          <p>Partially funded by</p>
          <img src="/assets/ESE.png"/>
        </a>

        <a href="https://learning.e-cat.uk/" target="_blank" class="e-learning-link">
          <i class="fa-solid fa-graduation-cap"></i>
          View our E-Learning Tool
        </a>

        <button class="ecat-tutorial" v-on:click="viewTutorial()">View E-CAT Demo</button>
      </div>
    </div>
  </div>
</template>

<script>
import authStore from "../store/auth-store";

export default {
  mounted() {
    this.loadButton();

    this.updateBeginButton(this.$router.currentRoute.value.path);
  },
  data() {
    return {
      showBegin: true,
      beginTitle: "Begin",
      currentUser: null,

      resume: false,
    }
  },
  methods: {
    loadButton() {
      var storedQuestionsJson = localStorage.getItem("@screening-questions");
      if (storedQuestionsJson != null && JSON.parse(storedQuestionsJson).length > 0) {
        this.beginTitle = "Resume";
        this.resume = true;
      } else {
        this.beginTitle = "Begin";
        this.resume = false;
      }
    },

    updateBeginButton(path) {
      if (path == "/") 
        this.showBegin = true;
      else
        this.showBegin = false;
    },

    continueAsGuest() {
      localStorage.setItem("@survey-questions", "[]");
      localStorage.setItem("@screening-questions", "[]");
      this.$router.push("/screening");
    },

    resumeAssessment() {
      this.$router.push("/screening");
    },

    login() {
      this.$router.push("/login");
    },

    newAssessment() {
      localStorage.setItem("@survey-questions", "[]");
      localStorage.setItem("@screening-questions", "[]");
      localStorage.removeItem("@is-simulated");

      if (this.isLoggedIn()) {
        this.$router.push("/screening");
      } else {
        this.$router.push("/demographic");
      }
    },

    getUser() {
      if (this.currentUser != null)
        return this.currentUser;

      if (this.user != null)
        return this.user;

      return null;
    },

    isLoggedIn() {
      return this.getUser() != null;
    },

    viewTutorial() {
      window.open("/assets/ecatvideo.mp4")
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  watch: {
    $route(to, from) {
      this.loadButton();

      this.updateBeginButton(to.path);
    },
    user (user) {
      this.currentUser = user;
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../breakpoints.scss";

  .content-wrapper {
    padding: 10px;
    img {
      max-width: 90%;
      margin: 15px auto;
    }

    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      line-height: 36px;
    }

    .btn-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    .btn {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .btn-accent {
      font-size: 14px;
    }

    p {
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    
  }

  .content-block {
    @include md {
      max-width: 650px;
      margin: 0 auto;
    }
  }

  .external-links-block {
    position: absolute;
    bottom: 10px;
    right: 0px;
    left: 0px;

    display: flex;
    flex-direction: column-reverse;

    justify-content: center;
    align-items: center;

    @include md {
      display: grid;
      grid-template-columns: auto 1fr auto;
      padding-left: 70px;
      padding-right: 10px;
    }

    .ese-logo {
      text-decoration: none;
      img {
        max-height: 50px;
        margin: 0px 15px;
      }

      p {
        margin: 0px;
        color: #000;
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
        font-family: 'Source Sans Pro', sans-serif;
      }
    }

    .e-learning-link {
      grid-column: 3;
      margin-bottom: 10px;
      background: #013B82;
      cursor: pointer;
      border-radius: 5px;
      padding: 8px 8px;
      color: #FFF;
      text-decoration: none;

      @include md {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;

        align-self: flex-end;

        i, svg {
          margin-bottom: 10px;
        }
      }
    }

  }

  .ecat-tutorial {
      grid-column: 3;
      margin-bottom: 10px;
      background: #013B82;
      cursor: pointer;
      border-radius: 5px;
      padding: 8px 8px;
      color: #FFF;
      text-decoration: none;
      outline: 0;
      border: 0;

      @include md {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        margin-top: 10px;
      }
    }

  // .ese-logo {
  //   position: absolute;
  //   bottom: 10px;
  //   left: 0;
  //   width: 100%;
  //   text-decoration: none;
  //   margin: 15px 0px;

  //   img {
  //     max-height: 50px;
  //     // max-width: 70%;
  //     margin: 0px 15px;
      
  //   }

  //   p {
  //     margin: 0px;
  //     color: #000;
  //     font-style: italic;
  //     font-size: 14px;
  //     margin-bottom: 5px;
  //     font-family: 'Source Sans Pro', sans-serif;
  //   }
  // }

  // .e-learning-link {
  //   position: absolute;
  //   bottom: 120px;
  //   left: 0;
  //   right: 0;
  //   text-align: center;
  // }
</style>
