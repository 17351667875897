<template>
  <div class="page-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>
    

    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <SurveySummary :assessmentDate="assessmentDate" :question-list="questions" />
      </div>
    </vue-final-modal>

    <div class="content-wrapper">
      <h1 class="page-title">Reports</h1>

      <div class="survey-list">
        <div class="survey" v-for="survey of surveys" v-bind:key="survey.surveyId">
           <div class="title">
            <div v-if="survey.score <= 5">
              <h1>Class 1</h1>
              <p>Low risk of complexity and adverse outcome</p>
            </div>
            <div v-else-if="survey.score > 5 && survey.score <= 10">
              <h1 class="pumpkin-yellow">Class 2</h1>
              <p>Moderate risk of complexity and adverse outcome</p>
            </div>
            <div v-else>
              <h1 class="berry-red">Class 3</h1>
              <p>High risk of operative complications and adverse outcome</p>
            </div>
          </div>

           <div class="score">
            <h3>E-CAT Score: {{ survey.score }} points</h3>
          </div>

          <h4>Assessment Date: {{survey.createdAt}}</h4>

          <button class="btn" v-on:click="navToSurvey(survey.surveyId)">View</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import SurveySummary from '../../../components/SurveySummary.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import authStore from "../../../store/auth-store";
import moment from 'moment'


export default {
  data() {
    return {
      showModal: false,
      assessmentDate: "",
      questions: "",

      isLoading: false,
      surveys: [],
    }
  },

  mounted() {
    if (this.user == null) {
      this.$router.push("/login");
      return;
    }
    this.search();
  },

  methods: {
    navToSurvey(surveyId) {
      this.isLoading = true;

      //this.$router.push({ path: `/admin/report/view/${surveyId}` })
       this.axios.get("/api/report/getbyid", { 
        params: { surveyId: surveyId, },
        headers: { 
          'Authorization' : 'Bearer ' + this.user.token }
        }).then((response) => {
          var survey = response.data;
          this.assessmentDate = moment(survey.createdAt).format("DD MMM yyyy"),
          this.questions = survey.questions;
          // We need to select the responses as selected for our survey component to make it easier
          // And change the question to the title
          for (let question of this.questions) {
            question.title = question.question;
            for (let response of question.responses) {
              response.isSelected = true;
            }
          }

          this.isLoading = false;
          this.showModal = true;
        });
    },

    search() {
      this.axios.get("/api/report/getuserreports", { headers: { 'Authorization' : 'Bearer ' + this.user.token } }).then((response) => {
        this.surveys = response.data.sort((a, b) => moment(b.createdAtDate).unix() - moment(a.createdAtDate).unix());
        console.log(this.surveys);
      })
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading,
    SurveySummary
    // VueGoodTable,
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    .page-title {
      font-size: 28px;
      background: #2A2A2E;
      color: #FFF;
      padding: 10px;
    }

    .survey-list {
      margin: 10px;

      .survey {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #2A2A2E;
        .title {
          margin-top: 10px;
          h1 {
            font-size: 52px;
          }
          p {
            margin-top: 8px;
          }
        }
      }

      .btn {
        margin-top: 10px;
      }

      .leaf-green {
        color: #2D5A27;
      }

      .pumpkin-yellow {
        color: #E99A10;
      }
      
      .berry-red {
        color: #920000;
      }

      .score {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
    
  }

   ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 80%;
    overflow-y: auto;
    max-height: 90%;

    img {
      width: 100%;
    }
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }
  
</style>
