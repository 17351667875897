<template>
  <button class="menu-toggle" v-bind:class="{ 'menu-toggle-black': collapsed }" @click="toggleMenu()">
    <i v-if="collapsed" class="fas fa-bars"></i>
    <!-- <i v-if="!collapsed" class="far fa-times-circle"></i> -->
    <i v-if="!collapsed" class="fas fa-times"></i>

    <span>E-CAT</span>
  </button>
  <nav-bar ref="nav" @on-item-clicked="setCollapsed"></nav-bar>
 
  <router-view/>
</template>

<script>
import NavBar from './components/NavBar';

export default {
  components: {
    "nav-bar": NavBar,
  },
  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    setCollapsed($event) {
      this.collapsed = $event;
    },
    toggleMenu() {
      this.collapsed = this.$refs.nav.toggleMenu();
    },
  }
}
</script>

<style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
</style>

<style lang="scss">
  @import "/breakpoints.scss";

  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Source Sans Pro', sans-serif;
  }


  .page-wrapper {
    box-sizing: border-box;
    width: 100%;
    padding-top: 40px;

    @include md {
      padding-top: 0px;
    }

    .content-wrapper {
      .uni-logo {
        max-height: 60px;
      }

      @include md {
        max-width: 90%;
        margin: 0 auto;
      }
    }
  }

  .btn {
    cursor: pointer;
    background: #013B82;
    display: block;
    width: 100%;
    text-align: center;

    outline: 0;
    border: 0;
    color: #FFF;

    font-size: 18px;
    padding: 5px 8px;
    border-radius: 5px;
    text-decoration: none;
  }

  .btn-delete {
    background: #C85250;
    display: block;
    width: 100%;

    outline: 0;
    border: 0;
    color: #FFF;

    font-size: 18px;
    padding: 5px 8px;
    border-radius: 5px;
    color: #FFF;
    text-decoration: none;
  }

  .btn-accent {
    background: #FFF;
    display: block;
    width: 100%;

    outline: 0;
    border: 1px solid #CECECE;
    color: #000;

    font-size: 18px;
    padding: 5px 8px;
    border-radius: 5px;
    text-decoration: none;
  }

  form {
    .form-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 10px;

      label {
        margin-bottom: 5px;
      }

      input, select {
        padding: 5px;
      }
    }
  }

  .input-picker {
    width: 100%;
    padding: 5px
  }

  .v-sidebar-menu {
    transition: 0.3s all;
    padding-top: 50px;

    @include md {
      padding-top: 0px;
    }
  }

  .v-sidebar-menu .vsm--title {
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .vsm--header {
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  .menu-toggle {
    position: fixed;
    left: 0px;
    z-index: 9999;
    transition: 0.3s all;
    width: 100%;
    text-align: left;

    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 18px;
    color: #FFF;
    outline: 0;
    border: 0px;
    font-size: 32px;
    background: #2A2A2E;

    display: flex;
    align-items: center;

    @include md {
      display: none;
    }

    span {
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;

      font-family: 'Source Sans Pro', sans-serif;
    }
  }

  .menu-toggle-black {
    color: #000;
    background: #ededed;
  }

  .v-sidebar-menu.vsm_collapsed {
    transform: translateX(-100%);
    @include md {
      transform: translateX(-0%);
    }
  }

  // .v-sidebar-menu.vsm_collapsed {
  //   transform: translateX(-100%);
  // }

</style>

