<template>
  <div class="page-wrapper content-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

    <h1>Contact Us Management</h1>

    <form>
      <div class="form-group">
        <label>Title</label>
        <input type="text" v-model="title" />
      </div>

      <div class="form-group">
        <label>Name</label>
        <input type="text" v-model="name" />
      </div>

      <div class="form-group">
        <label>Phone Number</label>
        <input type="text" v-model="phoneNumber" />
      </div>

      <div class="form-group">
        <label>Email</label>
        <input type="text" v-model="email" />
      </div>

      <div class="form-group">
        <label>Description</label>
        <textarea rows="5" v-model="description" />
      </div>

      <div class="form-group">
        <label>Upload Image</label>
        <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
      </div>

      <button class="btn" v-on:click.stop.prevent="save()">Add New</button>
    </form>

    <div class="content-list">
      <div class="contact-block" v-for="contact of contacts" v-bind:key="contact.id">
        <p><span>Title:</span> {{ contact.title }}</p>
        <p><span>Name:</span> {{ contact.name }}</p>
        <p><span>Phone:</span> {{ contact.phoneNumber }}</p>
        <p><span>Email:</span> {{ contact.email }}</p>
        <p>{{ contact.description }}</p>

        <img v-bind:src="contact.imageBase64"/>

        <button class="btn-delete" v-on:click.stop.prevent="remove(contact)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";
import authStore from "../../store/auth-store";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isLoading: false,

      title: "",
      name: "",
      phoneNumber: "",
      email: "",
      description: "",

      imageBase64: null,

      contacts: []
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isLoading = true;
      this.axios.get("/api/contact").then((response) => {
        this.contacts = response.data;
        this.isLoading = false;
      })
    },

    uploadImage(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imageBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    save() {
      this.isLoading = true;
      
      var dto = {
        title: this.title,
        name: this.name,
        phoneNumber: this.phoneNumber,
        email: this.email,
        description: this.description,

        imageBase64: this.imageBase64
      }

      this.axios.post("/api/contact", dto, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
        this.isLoading = false;
        this.toast.success("Successfully saved");
        
        this.title = "";
        this.name = "";
        this.phoneNumber = "";
        this.email = "";
        this.description = "";

        this.imageBase64 = "";
        this.fetch();
      }, (error) => {
        this.$router.push("/login");
        return;
      });
    },

    remove(aboutUs) {
      if (confirm("Are you sure you want to delete?")) {
        this.isLoading = true;
        this.axios.delete("/api/contact?id=" + aboutUs.id, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully removed");
          this.fetch();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  @import "../../breakpoints.scss";

  .content-wrapper {
    @include md {
      padding-left: 65px
    }
  }

   h1 {
    font-size: 28px;
    background: #2A2A2E;
    color: #FFF;
    padding: 10px;
  }

  form {
    padding: 10px;

    border-bottom: 3px solid #000;
  }

  // .contact-list {
  //   @include md {
  //     display: grid;
  //     grid-template-columns: 1fr 1fr;
  //   }
  // }

  .contact-block {
    padding: 10px;
    margin: 10px;
    border: 1px solid #CECECE;

    p {
      font-size: 12px;
      line-height: 12px;

      span {
        font-weight: bold;
      }
    }

    img {
      max-width: 100%;
      margin: 10px auto;
      
    }
  }
</style>
