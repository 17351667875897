<template>
  <div class="page-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

    <h1>Screening Questions</h1>
    <div class="content-wrapper">
      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
        <button class="modal__close" @click="showModal = false">
          <i class="far fa-times-circle"></i>
        </button>
        <div class="modal__content">
          <form v-if="editableQuestion != null">
            <div class="form-group">
              <label>Title</label>
              <input type="text" v-model="editableQuestion.title" />
            </div>

            <div class="form-group">
              <label>Information</label>
              <textarea  type="text" rows="4" v-model="editableQuestion.information" />
            </div>

            <div class="form-group">
              <label>Position</label>
              <input type="number" v-model="editableQuestion.position" />
            </div>

            <div class="form-group">
              <label>Upload Image</label>
              <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
            </div>

            <button class="btn" v-on:click.stop.prevent="save()">Save</button>

            <button class="btn-delete" v-on:click.stop.prevent="remove()">Delete</button>
          </form>
        </div>
      </vue-final-modal>

      <button class="btn" v-on:click="add()">Add New</button>

      <div class="list">
        <div class="question" v-for="(question) of questions" v-bind:key="question.id">
          <div class="title">
            <span>{{ question.title }}</span>
            <button v-on:click="edit(question)"><i class="far fa-edit"></i></button>
          </div>

          <div class="info">{{ question.information }}</div>

          <div class="position">Position: {{ question.position }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";
import authStore from "../../store/auth-store";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isLoading: false,
      questions: [],
      editableQuestion: null,
      showModal: false
    }
  },

  mounted() {
    this.loadQuestions();
  },

  methods: {
    loadQuestions() {
      this.axios.get("/api/screeningquestion").then((response) => {
        this.questions = response.data.sort((a, b) => a.position - b.position);
        this.isLoading = false;
      })
    },

    add() {
      this.editableQuestion = {
        title: "",
        information: "",
        position: this.questions.length + 1
      };

      this.showModal = true;
    },

    edit(question) {
      console.log(question);
      this.editableQuestion = JSON.parse(JSON.stringify(question));
      this.showModal = true;
    },

    save() {
      this.isLoading = true;
      if (this.editableQuestion.id == null) {
        this.axios.post("/api/screeningquestion", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully saved screening question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      } else {
        this.axios.put("/api/screeningquestion", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully saved screening question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    },

    remove() {
      if (confirm("Are you sure you want to delete this question?")) {
        this.editableQuestion.deleted = true;
        this.axios.put("/api/screeningquestion", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully removed screening question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    },

    uploadImage(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        //this.editableQuestion.imageBase64 = reader.result.split(",")[1];
        this.editableQuestion.imageBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 28px;
    background: #2A2A2E;
    color: #FFF;
    padding: 10px;
  }

  .content-wrapper {
    padding: 10px;
    
    .list {
      padding: 10px;

      .question {
        border: 1px solid #CECECE;
        margin-bottom: 10px;
        padding: 10px;

        .title {
          text-align: left;
          margin-bottom: 10px;

          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            flex: 1;
          }
        }

        .info {
          text-align: left;
          font-size: 12px;
        }

        .position {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  
  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 80%;    
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }

.btn-delete {
  margin-top: 10px;
}
</style>
