<template>
  <div class="page-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

    <h1>Survey Questions</h1>
    <div class="content-wrapper">
          <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
        <button class="modal__close" @click="showModal = false">
          <i class="far fa-times-circle"></i>
        </button>
        <div class="modal__content">
          <form v-if="editableQuestion != null">
            <div class="form-group">
              <label>Title</label>
              <input type="text" v-model="editableQuestion.title" />
            </div>

            <div class="form-group">
              <label>Information</label>
              <textarea type="text" rows="4" v-model="editableQuestion.information" />
            </div>

            <div class="form-group">
              <label>Position</label>
              <input type="number" v-model="editableQuestion.position" />
            </div>

            <div class="form-group">
              <label>Allow Multiple Responses</label>
              <input type="checkbox" v-model="editableQuestion.multipleResponses" />
            </div>

            <div class="form-group">
              <label>Upload Image</label>
              <input type="file" accept="image/*" @change="uploadImage($event, editableQuestion)" id="file-input">
            </div>

            <div class="form-group response-group">
              <label>
                <span>Responses</span>
                <button v-on:click="addResponse()"><i class="fas fa-plus-circle"></i></button>
              </label>
              <div class="response-list">
                <div class="response" v-for="response of editableQuestion.responses" v-bind:key="response.id">
                  <button class="remove-response" v-on:click="deleteResponse(response)"><i class="fas fa-trash-alt"></i></button>

                  <div class="response-group">
                    <label>Response</label>
                    <input type="text" v-model="response.response"/>
                  </div>

                  <div class="response-group">
                    <label>Weight</label>
                    <input type="text" v-model="response.weight"/>
                  </div>

                  <div class="response-group">
                    <label>Information</label>
                    <textarea rows="3" v-model="response.information"/>
                  </div>

                  <div class="form-group" style="padding-bottom: 15px;">
                    <label>Response Image</label>
                    <input type="file" accept="image/*" @change="uploadImage($event, response)" id="file-input">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group screening-group">
              <label><span>Linked To</span></label>

              <div class="screening-question" v-for="question of screeningQuestions" v-bind:key="question.id">
                <span>{{ question.title }}</span>
                <input type="checkbox" v-model="question.isSelected"/>
              </div>
            </div>

            <button class="btn" v-on:click.stop.prevent="save()">Save</button>

            <button class="btn-delete" v-on:click.stop.prevent="remove()">Delete</button>
          </form>
        </div>
      </vue-final-modal>

      <button class="btn" v-on:click="add()">Add New</button>

      <div class="list">
        <div class="question" v-for="(question) of questions" v-bind:key="question.id">
          <div class="title">
            <span>{{ question.title }}</span>
            <button v-on:click="edit(question)"><i class="far fa-edit"></i></button>
          </div>

          <div class="info">{{ question.information }}</div>

          <div class="position">Position: {{ question.position }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";
import authStore from "../../store/auth-store";

export default {
   setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isLoading: false,
      questions: [],
      screeningQuestions: [],
      editableQuestion: null,
      showModal: false
    }
  },

  mounted() {
    this.loadQuestions();

    this.loadScreeningQuestions();
  },

  methods: {
    loadQuestions() {
      this.axios.get("/api/question/get").then((response) => {
        this.questions = response.data.sort((a, b) => a.position - b.position);
        this.isLoading = false;
      })
    },

    loadScreeningQuestions() {
      this.axios.get("/api/screeningquestion").then((response) => {
        this.screeningQuestions = response.data.sort((a, b) => a.position - b.position);
      })
    },

    edit(question) {
      this.editableQuestion = JSON.parse(JSON.stringify(question));
      this.editableQuestion.responses = this.editableQuestion.responses.sort((a, b) => a.position - b.position);
      for (let screeningQuestion of this.screeningQuestions) {
        screeningQuestion.isSelected = false;

        if (this.editableQuestion.linkedTo != null && this.editableQuestion.linkedTo.includes(screeningQuestion.id))
          screeningQuestion.isSelected = true;
      }

      this.showModal = true;
    },

    add() {
      this.editableQuestion = {
        title: "",
        information: "",
        position: this.questions.length + 1,
        responses: [],
        multipleResponses: false,
        linkedTo: [],
        imageBase64: ""
      };

      this.showModal = true;
    },

    remove() {
      if (confirm("Are you sure you want to delete this question?")) {
        this.editableQuestion.deleted = true;
        this.axios.put("/api/question/put", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully removed question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    },

    addResponse() {
      this.editableQuestion.responses.push({
        response: "",
        weight: 0,
        position: this.editableQuestion.responses.length + 1,
        information: ""
      })
    },

    deleteResponse(response) {
      if (confirm("Are you sure you want to delete this response?")) {
        var idx = this.editableQuestion.responses.indexOf(response);
        if (idx == -1)
          return;

        this.editableQuestion.responses.splice(idx, 1);
      }
    },

    uploadImage(event, obj) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        obj.imageBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    save() {
      this.isLoading = true;

      this.editableQuestion.linkedTo = this.screeningQuestions.filter(s => s.isSelected).map(s => s.id);

      if (this.editableQuestion.id == null) {
        this.axios.post("/api/question/post", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully saved question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      } else {
        this.axios.put("/api/question/put", this.editableQuestion, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully saved question");
          this.showModal = false;
          this.editableQuestion = null;
          this.loadQuestions();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    },
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 28px;
    background: #2A2A2E;
    color: #FFF;
    padding: 10px;
  }

  .content-wrapper {
    padding: 10px;
    
    .list {
      padding: 10px;

      .question {
        border: 1px solid #CECECE;
        margin-bottom: 10px;
        padding: 10px;

        .title {
          text-align: left;
          margin-bottom: 10px;

          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            flex: 1;
          }
        }

        .info {
          text-align: left;
          font-size: 12px;
        }

        .position {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  

  
  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    // margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    form {
      .form-group {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 10px;

        label {
          margin-bottom: 5px;

          display: flex;
          span {
            flex: 1;
            align-items: center;
          }
        }

        input {
          padding: 5px;
        }
      }

      .screening-group {
        background: #f2f2f2;
        padding: 5px;

        .screening-question {
          border-bottom: 1px solid #CECECE;
          padding: 8px 5px;
          text-align: left;

          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            flex: 1;
          }
        }
      }

      .response-group {
        background: #f2f2f2;
        padding: 5px;
        .response-list {
          .response {
            border-bottom: 1px solid #CECECE;
            padding: 5px;
            position: relative;

            .remove-response {
              position: absolute;
              bottom: 5px;
              right: 5px;
              color: #FFF;
              background: red;
              border: 0;
              outline: 0;
              padding: 5px 10px;
              border-radius: 5px;
            }

            .response-group {
              display: flex;
              flex-direction: column;
            }

            // display: flex;
            // align-items: center;

            // span {
            //   flex: 1;
            // }

            // input {
            //   width: 80px;
            //   padding: 5px;
            //   text-align: center;
            // }
          }
        }
      }
    }
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }

.btn-delete {
  margin-top: 10px;
}
</style>
