<template>
  <div class="page-wrapper content-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

    <h1>About Us Management</h1>

    <form>
      <div class="form-group">
        <label>Information</label>
        <textarea rows="5" v-model="information" />
      </div>

      <div class="form-group">
        <label>Upload Image</label>
        <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
      </div>

      <button class="btn" v-on:click.stop.prevent="save()">Add New</button>
    </form>

    <div class="about-us-list">
      <div class="about-us-block" v-for="about of aboutUs" v-bind:key="about.id">
        <p>{{ about.information }}</p>

        <img v-bind:src="about.imageBase64"/>

        <button class="btn-delete" v-on:click.stop.prevent="remove(about)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";
import authStore from "../../store/auth-store";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isLoading: false,

      information: "",
      imageBase64: null,

      aboutUs: []
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isLoading = true;
      this.axios.get("/api/aboutus").then((response) => {
        this.aboutUs = response.data;
        this.isLoading = false;
      })
    },

    uploadImage(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imageBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    save() {
      this.isLoading = true;
      
      var dto = {
        information: this.information,
        imageBase64: this.imageBase64
      }

      this.axios.post("/api/aboutus", dto, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
        this.isLoading = false;
        this.toast.success("Successfully saved");
        this.information = "";
        this.imageBase64 = "";
        this.fetch();
      }, (error) => {
        this.$router.push("/login");
        return;
      });
    },

    remove(aboutUs) {
      if (confirm("Are you sure you want to delete?")) {
        this.isLoading = true;
        this.axios.delete("/api/aboutus?id=" + aboutUs.id, { headers: { 'Authorization' : 'Bearer ' + this.user.token }}).then((response) => {
          this.toast.success("Successfully removed");
          this.fetch();
        }, (error) => {
          this.$router.push("/login");
          return;
        });
      }
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  @import "../../breakpoints.scss";

  .content-wrapper {
    @include md {
      padding-left: 65px
    }
  }

   h1 {
    font-size: 28px;
    background: #2A2A2E;
    color: #FFF;
    padding: 10px;
  }

  form {
    padding: 10px;

    border-bottom: 3px solid #000;
  }

  .about-us-list {
    @include md {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .about-us-block {
    padding: 10px;
    margin: 10px;
    border: 1px solid #CECECE;

    

    p {
      font-size: 12px;
      line-height: 12px;
    }

    img {
      max-width: 100%;
      margin: 10px auto;
      
    }
  }
</style>
