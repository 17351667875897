import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vfmPlugin from 'vue-final-modal'
import Toast from "vue-toastification";

//import 'vue-js-modal/dist/styles.css'
import "vue-toastification/dist/index.css";
import authStore from "./store/auth-store";

const app = createApp(App);
app.use(router);
app.use(Toast, { position: "bottom-right" });
app.use(VueAxios, axios);
app.use(vfmPlugin);
app.use(authStore);

authStore.commit("initialiseStore");

app.mount("#app");

// import authStore from "./store/auth-store";
// console.log(authStore);

//createApp(App).use(router).mount('#app')
