<template>
  <sidebar-menu :menu="menu" v-model:collapsed="collapsed" :hideToggle="'false'" @item-click="onItemClick" />
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import authStore from "../store/auth-store";

export default {
  emits: ['onItemClicked'],
  components: {
    SidebarMenu
  },
  mounted() {
    this.buildMenu();
  },
  data() {
    return {
      currentUser: null,
      menu: [],
      collapsed: true,
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    onItemClick($event) {
      if (!this.isMobile())
        return;

      var collapsed = this.toggleMenu();
      this.$emit('onItemClicked', collapsed);
    },
    toggleMenu() {
      if (!this.isMobile())
        return;

      this.collapsed = !this.collapsed;  
      return this.collapsed;
    },
    buildMenu() {
      this.menu = [
        {
          header: 'E-CAT',
          hiddenOnCollapse: true,
        },
        {
          href: { path: '/' },
          title: 'Home',
          icon: 'fas fa-home'
        },
        {
          href: { path: '/about' },
          title: 'About',
          icon: 'fas fa-info-circle' 
        },
        {
          href: 'https://learning.e-cat.uk/',
          title: 'E-Learning',
          icon: 'fa-solid fa-chalkboard-user',
          attributes: {
            target: '_blank'
          },
          external: true
        },
        {
          href: { path: '/contact' },
          title: 'Contact',
          icon: 'far fa-address-book'
        },
        {
          href: { path: '/login' },
          title: 'Login',
          icon: 'far fa-user',
          hidden: this.isLoggedIn() ? true : false
        },
        {
          href: { path: '/admin/report' },
          title: 'Report',
          icon: 'far fa-file-alt',
           hidden: this.isLoggedIn() && this.isAdmin() ? false : true
        },
        {
          href: { path: '/admin/user-report' },
          title: 'Reports',
          icon: 'far fa-file-alt',
           hidden: this.isLoggedIn() && !this.isAdmin() ? false : true
        },
        {
          href: { path: '/admin/screening-questions' },
          title: 'Screening Questions',
          icon: 'fas fa-question-circle',
          hidden: this.isLoggedIn() && this.isAdmin() ? false : true
        },
        {
          href: { path: '/admin/survey-questions' },
          title: 'Survey Questions',
          icon: 'far fa-question-circle',
          hidden: this.isLoggedIn() && this.isAdmin() ? false : true
        },
        {
          href: { path: '/admin/about-us-management' },
          title: 'About Us Management',
          icon: 'fa-solid fa-circle-info',
          hidden: this.isLoggedIn() && this.isAdmin() ? false : true
        },
        {
          href: { path: '/admin/contact-us-management' },
          title: 'Contact Us Management',
          icon: 'fa-solid fa-address-book',
          hidden: this.isLoggedIn() && this.isAdmin() ? false : true
        },
        {
          href: { path: '/changepassword' },
          title: 'Change Password',
          icon: 'fas fa-key',
          hidden: this.isLoggedIn() ? false : true
        },
        {
          href: { path: '/logout' },
          title: 'Logout',
          icon: 'fas fa-sign-out-alt',
          hidden: this.isLoggedIn() ? false : true
        },
      ]
    },

    getUser() {
      if (this.currentUser != null)
        return this.currentUser;

      if (this.user != null)
        return this.user;

      return null;
    },

    isLoggedIn() {
      return this.getUser() != null;
    },

    isAdmin() {
      if (!this.isLoggedIn())
        return false;

      var user = this.getUser();
      return user.role == 'Admin';
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  watch: {
    user (user) {
      this.currentUser = user;
      this.buildMenu();
      this.$forceUpdate();
    },
    collapsed (val) {
      
    },
  }
}
</script>

<style scoped>
  
</style>
