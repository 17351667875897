<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <img class="uni-logo" src="/assets/logo.png"/>

      <h1>About</h1>

      <div class="about__block" v-for="(about, index) of aboutUs" v-bind:key="about.id" :class="{'reverse': index % 2 !== 0 }">
        <div class="about-info">
          <p v-html="about.information"></p>
        </div>

        <div class="about-img">
          <img v-bind:src="about.imageBase64"/>
        </div>

      </div>

      <!-- <div class="about__block">
        <div class="about-info">
          <p>
            The Endodontic Complexity Assessment Tool (E-CAT), is being developed for Dr. Obyda Essam at The Royal Liverpool University Dental Hospital 
            by Peter Duff and Aishah Senin, Alumni of the University of Liverpool Computer Science department. It originally began as a final year project 
            undertaken by Peter, assigned by Phil Jimmieson, it has since expanded since the completion of the project for the Computer Science department.
          </p>

          <p>
            The need for endodontic treatment in dental care is an established and well attested fact in the literature. A substantial perceived need for 
            referring endodontic cases to endodontic specialists has been reported. There is always a moral, legal and ethical obligation when determining 
            the difficulty of any form of dental treatment in order to ensure a referral is made if found in the best interests of the patient. In order 
            to improve the success rate for endodontic treatment by general dental practitioners (GDPs), the referral of the more complex cases to an 
            experienced clinician or endodontist should be made possible in the best interest of the patient and most predictable treatment outcome.
          </p>
        </div>

        <div class="about-img">
          <img src="/assets/stock1.jpg"/>
        </div>
      </div>

      <div class="about__block reverse">
        <div class="about-info">
          <p>
            In order to be able to refer difficult endodontic cases appropriately, two requirements need to be satisfied. GDPs need to be able to predictably 
            identify cases with higher difficulties and treat or refer to the appropriate practitioner and there need to be sufficient number of endodontic 
            specialists or dentists with further advanced skills in endodontics to refer to. The tool simply aims to provide a quick and easy way to assess 
            how difficult an endodontic treatment is predicted to be. For example, a “3” outcome would indicate a high risk and complexity where a 1 however 
            would suggest a more straightforward endodontic treatment with low risk of complications.
          </p>
          <p>
            The tool will provide you with E-CAT score, 0-4 indicates class 1, 5-9 indicate class 2, and any ECAT score above 10 is considered class 3 complexity. 
            The higher the E-CAT score, the higher the risk of complexity expected.
          </p>
        </div>

        <div class="about-img">
          <img src="/assets/stock2.jpg"/>
        </div>
      </div> -->
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      aboutUs: []
    }
  },

  mounted() {
    this.axios.get("/api/aboutus").then((response) => {
      this.aboutUs = response.data;
    })
  },
}
</script>

<style lang="scss" scoped>
  @import "../breakpoints.scss";

  .content-wrapper {
    padding: 10px;

    img {
      max-width: 90%;
      margin: 15px auto;
    }

    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      line-height: 36px;
      @include md {
        margin-bottom: 30px;
      }
    }

    .reverse {
      @include md {
        flex-direction: row-reverse !important;
      }
    }

    .about__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      @include md {
        flex-direction: row;
        margin-bottom: 30px;
      }

      .about-info {
        display: flex;
        flex-direction: column;
        text-align: center;
        flex: 2;

        p {
          line-height: 20px;
          margin-bottom: 10px;
          font-size: 14px;

          @include md {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }

      .about-img {
        flex: 1;
        img {
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }
  }
</style>