<template>
  <div class="content-wrapper">
    <vue-final-modal v-model="showInfoModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showInfoModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <p>{{ information }}</p>
      </div>
    </vue-final-modal>

    <vue-final-modal v-model="showImageModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showImageModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <img v-bind:src="imageBase64"/>
      </div>
    </vue-final-modal>

    <loading v-model:active="isLoading" :is-full-page="true"/>

    <div class="simulated-dialog">
      <button v-on:click="isSimulated = false">
        <i class="fa-solid fa-circle-check" v-bind:class="{ 'btn-positive': isSimulated == false }"></i>
        <span>Patient</span>
      </button>
      <button v-on:click="isSimulated = true">
        <i class="fa-solid fa-circle-check" v-bind:class="{ 'btn-positive': isSimulated == true }"></i>
        <span>Simulated</span>
      </button>
    </div>

    <h2>Please answer the following screening Questions:</h2>

    <div class="screening-questions__list">
      <div class="screening-question" v-for="question of questions" v-bind:key="question.id">
        <h3 class="title">
          <span>{{ question.title }}</span>  

          <button class="btn-info" v-if="question.imageBase64 != null && question.imageBase64 != ''" v-on:click="showImage(question.imageBase64)">
            <i class="fas fa-camera-retro"></i>
          </button>
          <button class="btn-info" v-if="question.information != null && question.information != ''" v-on:click="showInformation(question)">
            <i class="fas fa-info-circle"></i>
          </button>
        </h3>

        <div class="screening-question-group">
          <p class="question">{{ question.question }}</p>

          <button class="btn" v-on:click="toggleResponse(question)" v-bind:class="{ 'btn-positive': question.response == true }">
            {{ question.response == null || question.response == false ? 'NO' : 'YES'}}
          </button>
        </div>
      </div>
    </div>

    <div class="action-buttons">
      <button class="btn" v-on:click="continueAssessment()">Continue Assessment</button>

      <button class="btn-accent btn-back" v-on:click="back()">Back</button>
      <!-- <router-link class="btn-accent" to="/">Back</router-link> -->
    </div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data() {
    return {
      isLoading: false,
      questions: [],

      information: "",
      imageBase64: "",

      showInfoModal: false,
      showImageModal: false,

      isSimulated: null
    }
  },

  mounted() {
    this.isLoading = true;
    this.axios.get("/api/screeningquestion").then((response) => {
      this.questions = response.data;
      this.isLoading = false;

      // Check if we have local questions and if so we want to update our current question set
      var storedQuestionsJson = localStorage.getItem("@screening-questions");
      if (storedQuestionsJson != null) {
        this.updateFromStoredQuestions(JSON.parse(storedQuestionsJson));
      }

      var isSimulated = localStorage.getItem("@is-simulated");
      this.isSimulated = isSimulated == null ? null : isSimulated == "true";
    })
  },

  methods: {
    back() {
      if (confirm("Are you sure you want to go back?")) {
        this.$router.push("/");
      }
    },
    updateFromStoredQuestions(storedQuestions) {
      for (let question of this.questions) {
        var storedQuestion = storedQuestions.find(q => q.id == question.id);
        if (storedQuestion == null)
          continue;

        question.response = storedQuestion.response;
      }
    },

    toggleResponse(question) {
      if (question.response == null)
        question.response = false;

      question.response = !question.response;
      this.saveQuestions(this.questions);
    },

    showInformation(question) {
      this.information = question.information;
      this.showInfoModal = true;
    },

    showImage(imageBase64) {
      this.imageBase64 = imageBase64;
      this.showImageModal = true;
    },

    saveQuestions(questions) {
      localStorage.setItem("@screening-questions", JSON.stringify(questions));
    },
    
    continueAssessment() {
      if (this.isSimulated == null) {
        alert("Please select if this is a patient or a test");
        return;
      }
      this.$router.push("/survey");
    }
  },
  watch: {
    // whenever question changes, this function will run
    isSimulated(isSim) {
      localStorage.setItem("@is-simulated", isSim);
    }
  },


  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  @import "../../breakpoints.scss";

  .content-wrapper {
    
    .screening-questions__list {
      margin-top: 20px;

      @include md {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
      }

      .screening-question {
        margin-bottom: 10px;
        border: 1px solid #013B82;


        .title {
          padding: 10px;
          background: #000;
          color: #FFF;
          font-weight: bold;

          display: flex;

          span {
            flex: 1;
            text-align: left;
          }

          .btn-info {
            color: #FFF;
            outline: 0;
            background: transparent;
            border: 0;
          }
        }

        .screening-question-group {
          display: flex;
          flex-direction: row;
          align-items: center;

          @include md {
            padding: 10px;
          }

          .question {
            padding: 10px;
            flex: 1;
          }

          .btn {
            background: #000000;
            border-radius: 0px;
            width: 60px;
            margin-left: 7px;
            margin-right: 7px;
            padding: 10px;

            border-radius: 60px;
          }

          .btn-positive {
            background: #013B82 !important;
            transition: all 0.5s ease;
          } 
        }

       

        // .btn {
        //   background: #000000;
        //   border-radius: 0px;
        //   margin-top: 15px;
        // }

        
      }
    }
  }

  .action-buttons {
    a {
      margin-bottom: 10px;
    }
  }

  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 80%;

    img {
      width: 100%;

      @include md {
        width: 80%;
      }
    }
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }

  .simulated-dialog {
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 10px 0px;

    background: #ebe8e8;
    border-radius: 10px;
    justify-content: center;
    align-content: center;

    @include md {
      gap: 10px;
      padding: 10px;
    }

    .btn-positive {
      color: green;
    }

    button {
      width: 100%;
      height: 60px;

      display: flex;
      flex-direction: column;

      background: #FFF;
      border: 1px solid #CECECE;

      font-size: 16px;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;

      padding: 0px 0px;
      cursor: pointer;
      border-radius: 10px;
      @include md {
        display: grid;
        grid-template-columns: 15px 1fr;
      
        font-size: 28px;
        padding: 0px 20px;
        gap: 20px;
        grid-template-columns: 30px 1fr;
        text-align: left;
      }

      span {
        font-family: 'Source Sans Pro', sans-serif;
        text-align: left;
      }

      i, svg {
        color: #CECECE;
        margin-top: 4px;
      }
    }
  }

  .btn-back {
    margin-top: 10px;
    cursor: pointer;
  }
</style>
