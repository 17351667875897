<template>
  <div class="page-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>
    

    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">
        <i class="far fa-times-circle"></i>
      </button>
      <div class="modal__content">
        <SurveySummary :assessmentDate="assessmentDate" :question-list="questions" />
      </div>
    </vue-final-modal>

    <div class="content-wrapper">
      <h1>Report</h1>

      <div class="filters">
        <div class="filter-group">
          <label>Start Date</label>
          <datepicker class="input-picker" v-model="startDate" />
        </div>

        <div class="filter-group">
          <label>End Date</label>
          <datepicker class="input-picker" v-model="endDate" />
        </div>

        <div class="filter-group">
          <label>Class</label>
          <select v-model="surveyClass">
            <option value="0">All</option>
            <option value="1">Class 1</option>
            <option value="2">Class 2</option>
            <option value="3">Class 3</option>
          </select>
        </div>

        <div class="filter-group">
          <label>Email</label>
          <input type="email" v-model="email"/>
        </div>

        <div class="filter-group">
          <label>Reference</label>
          <input type="reference" v-model="referenceNo"/>
        </div>

        <div class="filter-group">
          <label>Experience</label>
          <select v-model="experience">
            <option value="">All</option>
            <option value="Student">Student</option>
            <option value="Qualified Dentist">Qualified Dentist</option>
          </select>
        </div>

        <div class="filter-group" v-if="experience == 'Student'">
          <label>Undergraduate Year</label>
          <select v-model="undergraduateYear">
            <option value="">All</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>

         <div class="filter-group" v-if="experience == 'Qualified Dentist'">
          <label>Year of BDS graduation (or equivalent)</label>
          <input type="number" v-model="graduationYear" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
        </div>

        <div class="filter-group" v-if="experience == 'Qualified Dentist'">
          <label>Country of undergraduate qualification</label>
          <input type="text" v-model="countryOfQualification"/>
        </div>

        <div class="filter-group" v-if="experience == 'Qualified Dentist'">
          <label>Main area of practice</label>
          <select v-model="areaOfPractice">
            <option value="">All</option>
            <option value="Private practice">Private practice</option>
            <option value="NHS general practice">NHS general practice</option>
            <option value="NHS community/salaried dental service">NHS community/salaried dental service</option>
            <option value="Hospital dental service">Hospital dental service</option>
          </select>
        </div>

        <div class="filter-group" v-if="experience == 'Qualified Dentist'">
          <label>Postgraduate training in endodontics? </label>
          <select v-model="havePostgraduateEndodontics">
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <div class="filter-group" v-if="havePostgraduateEndodontics == 'true'">
          <label>Postgraduate training in endodontics? </label>
          <select v-model="postgraduateEndodonticsExperience">
            <option value="">All</option>
            <option value="Specialist">Specialist</option>
            <option value="Special interest in Endodontics">Special interest in Endodontics (e.g. MSc, Diploma)</option>
            <option value="Specialty Trainee (or equivalent)">Specialty Trainee (or equivalent)</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="filter-group" v-if="havePostgraduateEndodontics == 'true' && postgraduateEndodonticsExperience == 'Other'">
          <label>Other</label>
          <input type="text" v-model="postgraduateEndodonticsExperienceOther" placeholder="Other..."/>
        </div>

        <div v-if="havePostgraduateEndodontics == 'true'">
          <div class="filter-group" v-if="postgraduateEndodonticsExperience == 'Specialty Trainee (or equivalent)'">
            <label>Endodontics Year</label>
            <select v-model="postgraduateEndodonticsYear">
              <option value="">All</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div v-else>
            <div class="filter-group">
              <label>{{ postgraduateEndodonticsExperience == 'Other' ? "Year of qualification" : "Year of graduation" }}</label>
              <input type="number" v-model="postgraduateEndodonticsYear" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
            </div>
          </div>
        </div>

        <div class="filter-group">
          <label>Simulated</label>
          <select v-model="isSimulated">
            <option value="-1">All</option>
            <option value="1">Simulated Patient</option>
            <option value="0">Real Patient</option>
          </select>
        </div>
      </div>

      <div style="margin: 10px;">
        <button class="btn" v-on:click="search()">Search</button>
      </div>

      <div class="table">
        <vue-good-table :columns="columns" :rows="rows" :pagination-options="{ enabled: true }">
         <template #table-row="props">
            <span v-if="props.column.field == 'btn'">
              <button class="btn" v-on:click="navToSurvey(props.row.surveyId)">View</button>
            </span>
          </template>
        </vue-good-table>
      </div>

    </div>

  </div>
</template>

<script>
import SurveySummary from '../../../components/SurveySummary.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import authStore from "../../../store/auth-store";
import moment from 'moment'
// https://xaksis.github.io/vue-good-table/guide/configuration/pagination-options.html#enabled
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

import Datepicker from 'vue3-datepicker'

export default {
  data() {
    return {
      showModal: false,
      assessmentDate: "",
      questions: "",

      isLoading: false,
      startDate: new Date(),
      endDate: new Date(),
      surveyClass: "0",
      email: "",
      referenceNo: "",

      experience: "",
      undergraduateYear: "",
      graduationYear: "",
      countryOfQualification: "",
      areaOfPractice: "",
      havePostgraduateEndodontics: "",
      postgraduateEndodonticsExperience: "",
      postgraduateEndodonticsYear: "",
      postgraduateEndodonticsExperienceOther: "",
      isSimulated: "-1",

      rows: [],
      columns: [
        { label: 'Date', field: 'createdAt' },
        { label: 'Score', field: 'score' },
        { label: 'Class', field: 'surveyClass' },
        { label: 'Email', field: 'email' },
        { label: 'Reference', field: 'reference' },
        { label: 'Patient', field: 'simulatedPatient' },
        { label: 'View', field: 'btn' },
      ],
    }
  },

  mounted() {
    this.search();
  },

  methods: {
    navToSurvey(surveyId) {
      this.isLoading = true;

      //this.$router.push({ path: `/admin/report/view/${surveyId}` })
       this.axios.get("/api/report/getbyid", { 
        params: { surveyId: surveyId, },
        headers: { 
          'Authorization' : 'Bearer ' + this.user.token }
        }).then((response) => {
          var survey = response.data;
          this.assessmentDate = moment(survey.createdAt).format("DD MMM yyyy"),
          this.questions = survey.questions;
          // We need to select the responses as selected for our survey component to make it easier
          // And change the question to the title
          for (let question of this.questions) {
            question.title = question.question;
            for (let response of question.responses) {
              response.isSelected = true;
            }
          }

          this.isLoading = false;
          this.showModal = true;
        });
    },

    search() {
      this.axios.get("/api/report/get", { 
      params: {
        startDate: this.startDate,
        endDate: this.endDate,
        surveyClass: this.surveyClass,
        experience: this.experience,
        email: this.email,
        referenceNo: this.referenceNo,
        undergraduateYear: this.experience == 'Student' ? this.undergraduateYear : "",
        graduationYear: this.experience == 'Qualified Dentist' ? this.graduationYear : "",
        countryOfQualification: this.experience == 'Qualified Dentist' ? this.countryOfQualification : "",
        areaOfPractice: this.experience == 'Qualified Dentist' ? this.areaOfPractice : "",
        havePostgraduateEndodontics: this.experience == 'Qualified Dentist' ? this.havePostgraduateEndodontics : "",
        postgraduateEndodonticsExperience: this.havePostgraduateEndodontics == 'true' ? this.postgraduateEndodonticsExperience : "",
        postgraduateEndodonticsExperienceOther: this.havePostgraduateEndodontics == 'true' && this.postgraduateEndodonticsExperience == 'Other' ? this.postgraduateEndodonticsExperienceOther : "",
        postgraduateEndodonticsYear: this.havePostgraduateEndodontics == 'true' ? this.postgraduateEndodonticsYear : "",
        isSimulated: this.isSimulated,
      },
      headers: { 
        'Authorization' : 'Bearer ' + this.user.token }
      }).then((response) => {
        this.rows = response.data;
      });
    }
  },
  computed: {
    user() {
      return authStore.state.user;
    }
  },
  components: {
    Loading,
    VueGoodTable,
    Datepicker,
    SurveySummary
    // VueGoodTable,
  },
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    h1 {
      font-size: 28px;
      background: #2A2A2E;
      color: #FFF;
      padding: 10px;
    }

    .table {
      padding: 10px;
    }

    .filters {
      display: grid;
      gap: 5px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 10px;

      .filter-group {
        display: flex;
        flex-direction: column;
        text-align: left;
        
        label {
          font-weight: bold;
          margin-bottom: 5px;
        }

        
        input, select {
          width: 100%;
          padding: 5px
        }
      }
    }
  }

   ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1.3rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    width: 80%;
    overflow-y: auto;
    max-height: 90%;

    img {
      width: 100%;
    }
  }
  .modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    background: transparent;
    border: 0px;
    outline: 0px;
  }
  
</style>
