<template>
  <div class="page-wrapper">
    
  </div>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import authStore from "../store/auth-store";

export default {
  mounted() {
    authStore.commit("setUser", null);
    this.$router.push("/");  
  },
}
</script>

<style lang="scss" scoped>
  
</style>
