<template>
  <div class="page-wrapper">
    <div class="content-wrapper">
      <loading v-model:active="isLoading" :is-full-page="true"/>
      <img class="uni-logo" src="/assets/logo.png"/>

      <h1>Contact</h1>

      <form v-on:submit.prevent="onSubmit">
        <div class="form-group">
          <label>Name</label>
          <input type="text" placeholder="Name" v-model="contactName" />
        </div>

        <div class="form-group">
          <label>Email</label>
          <input type="text" placeholder="Email" v-model="email"/>
        </div>

         <div class="form-group">
          <label>Message</label>
          <textarea rows="5" type="text" placeholder="Message" v-model="message"/>
        </div>

        <button type="submit" class="btn">Send</button>
      </form>

      <div class="contacts__block">
        <div class="contact" v-for="contact of contacts" v-bind:key="contact.id">
          <h3>{{ contact.title }}</h3>
          <div class="contact-bio">
            <img v-bind:src="contact.imageBase64"/>

            <div>
              <p>{{ contact.name }}</p>
              <p>{{ contact.phoneNumber }}</p>
              <p><a :href="`mailto:${contact.email}`">{{ contact.email }}</a></p>
            </div>
          </div>

          <p class="bio" v-html="contact.description">
          </p>
        </div>

        <!-- <div class="contact">
          <h3>Technical</h3>
          <div class="contact-bio">
            <img src="/assets/peterDuff.jpg"/>

            <div>
              <p>Peter Duff</p>
              <p>(+44)7842110896</p>
              <p><a href="mailto:peterduff@gmail.com">peterduff@gmail.com</a></p>
            </div>
          </div>

          <p class="bio">
            A University of Liverpool Computer Science graduate, specialising in the development of medical applications. 
            The development of this tool was completed at the request of Dr. Essam, to provide the capacity of applying a 
            technical solution to the assessment of Root Canal procedures. 
            Peter currently works as a Front-End Developer for LiftUpp.
          </p>
        </div>

        <div class="contact">
          <h3>Technical</h3>
          <div class="contact-bio">
            <img src="/assets/aishahSenin.jpg"/>

            <div>
              <p>Aishah Senin</p>
              <p>(+44)7984656781</p>
              <p><a href="mailto:aishahsenin@gmail.com">aishahsenin@gmail.com</a></p>
            </div>
          </div>

          <p class="bio">
            Also a Computer Science graduate of the University of Liverpool, Aishah originally hails from sunny Singapore. 
            Her skills as a Software Engineer have provided a strong coherent structure to the design of the e-cat system, 
            and maintained this ethos outside of the program by setting up and maintaining the source control used in the implementation of e-cat. 
            Aishah currently works as a Software Engineer for LiftUpp.
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useToast } from "vue-toastification";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      contactName: "",
      email: "",
      message: "",

      isLoading: false,

      contacts: []
    }
  },
  mounted() {
    this.axios.get("/api/contact").then((response) => {
      this.contacts = response.data;
    })
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;

      var dto = {
        name: this.contactName,
        email: this.email,
        message: this.message
      }

      try {
          var response = await this.axios.post("/api/email/post", dto);
          this.toast.success("Successfully sent email");
          this.contactName = "";
          this.email = "";
          this.message = "";
        } catch (e) {
          this.toast.error("Error sending email");
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
    }
  },
  components: {
    Loading
  },
}
</script>

<style lang="scss" scoped>
  @import "../breakpoints.scss";

  .content-wrapper {
    padding: 10px;

    img {
      max-width: 90%;
      margin: 15px auto;
    }

    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 26px;
      line-height: 36px;
      @include md {
        margin-bottom: 30px;
      }
    }

    .contacts__block {
      margin-top: 20px;

      @include md {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
      }

      .contact {
        margin-bottom: 20px;
        border-bottom: 1px solid #CECECE;
        padding-bottom: 20px;

        &:last-child {
          border-bottom: 1px solid #FFF;
        }


        @include md {
          &:last-child {
            border-bottom: 1px solid #CECECE;
          }
        }


        h3 {
          font-size: 22px;
          margin-bottom: 5px;
          font-weight: bold;
        }

        img {
          margin: 0 auto;
          border: 1px solid #000;
        }

        p {
          margin-bottom: 5px;
        }

        .bio {
          margin-top: 15px;
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
  }
  
</style>