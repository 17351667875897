<template>
  <div  class="content-wrapper">
    <loading v-model:active="isLoading" :is-full-page="true"/>

    <div id="survey">
      <div class="title">
        <div v-if="score <= 5">
          <h1>Class 1</h1>
          <p>Low risk of complexity and adverse outcome</p>
        </div>
        <div v-else-if="score > 5 && score <= 11">
          <h1 class="pumpkin-yellow">Class 2</h1>
          <p>Moderate risk of complexity and adverse outcome</p>
        </div>
        <div v-else>
          <h1 class="berry-red">Class 3</h1>
          <p>High risk of operative complications and adverse outcome</p>
        </div>
      </div>

      <div class="score">
        <h3>E-CAT Score: {{ score }} points</h3>
      </div>

      <h4>Assessment Date: {{assessmentDate}}</h4>
      

      <div class="question__list">
        <div class="question" v-for="(question, index) of questions.sort((a, b) => a.groupPosition - b.groupPosition || a.position - b.position)" v-bind:key="question.id">
          <div class="question-title">{{ index + 1 }}. {{ question.title }}</div>
          <div class="response__list">
            <div class="response" v-for="response of question.responses.sort((a, b) => a.weight - b.weight)" v-bind:key="response.id"
            v-bind:class="{ 'response-pumpkin-yellow': response.weight > 0, 'response-orange': response.weight > 5 && response.weight <= 10, 'response-berry-red': response.weight > 10}">
              <span>{{ response.response }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <button class="btn img-btn" v-on:click="saveToImage()">Download as Image</button>  
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Loading from 'vue-loading-overlay';
import download from 'downloadjs';

export default {
  props: ['assessmentDate', 'questionList'],

  data() {
    return {
      isLoading: false,
      score: 0,
      questions: [],
    }
  },

  mounted() {
    this.loadQuestions(this.questionList);
  },

  watch: {
    questionList: function(newVal, oldVal) {
      this.loadQuestions(newVal);
    }
  },

  methods: {
    saveToImage() {
      this.isLoading = true;
      var node = document.getElementById('survey');
      html2canvas(node).then((canvas) => {
        download(canvas.toDataURL(), 'survey.png');
        this.isLoading = false;
      });
    },
    loadQuestions(questions) {
      this.questions = [];
      var score = 0;
      for (let question of questions) {
        var responses = question.responses.filter(r => r.isSelected == true);
        if (responses.length > 0) {
          question.responses = responses;
          this.questions.push(question);
        }

        var responseScore = 0;
        for (let response of responses) {
          responseScore += response.weight;
          score += responseScore;
        }

        if (responseScore <= 5)
          question.groupPosition = 0;
          //question.group = "Low Complexity";
        else if (responseScore > 5 && responseScore <= 10)
          question.groupPosition = 1;
          //question.group = "Medium Complexity";
        else
          question.groupPosition = 2;
          //question.group = "High Complexity";
      }

      this.score = score;
      this.$emit('update:score', this.score);
    },
  },
  components: {
    Loading
  }
}
</script>

<style lang="scss" scoped>
  .content-wrapper {
    .title {
      margin-top: 10px;
      h1 {
        font-size: 52px;
      }
      p {
        margin-top: 8px;
      }
    }

    .leaf-green {
      color: #2D5A27;
    }

    .pumpkin-yellow {
      color: #E99A10;
    }
    
    .berry-red {
      color: #920000;
    }

    .score {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .question__list {
      margin-top: 20px;
      .question {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #013B82;
        text-align: left;

        .response__list {
          margin-top: 5px;
          .response {
            padding: 8px;
            display: flex;
            align-items: center;
            i, svg {
              padding-right: 10px;
            }

            span {
              border-left: 2px solid #000;
              padding-left: 10px;
            }
          }

          .response-leaf-green {
            background: #2D5A27;
            color: #FFF;
          }

          .response-pumpkin-yellow {
            background: #FFCC00;
            color: #FFF;
          }

          .response-berry-red {
            background: #920000;
            color: #FFF;
          }

          .response-orange {
            background: #FE8C00;
            color: #FFF;
          }
        }
      }
    }
  }

  .action-buttons {
    a, button {
      margin-bottom: 10px;
    }
  }

  .img-btn {
    margin-bottom: 20px;
    background: #000;
    color: #FFF;
  }
</style>
