import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'
import About from '../pages/About.vue'
import Contact from '../pages/Contact.vue'
import Demographic from '../pages/Screening/Demographic.vue'
import Screening from '../pages/Screening/Screening.vue'
import Survey from '../pages/Screening/Survey.vue'
import Summary from '../pages/Screening/Summary.vue'
import ChangePassword from '../pages/ChangePassword.vue'

import ScreeningQuestions from '../pages/admin/ScreeningQuestions.vue'
import SurveyQuestions from '../pages/admin/SurveyQuestions.vue'
import Report from '../pages/admin/report/Report.vue'
import UserReport from '../pages/admin/report/UserReport.vue'
import AboutUsManagement from '../pages/admin/AboutUsManagement.vue'
import ContactUsManagement from '../pages/admin/ContactUsManagement.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      { path: 'demographic', component: Demographic },
      { path: 'screening', component: Screening },
      { path: 'survey', component: Survey },
      { path: 'summary', component: Summary }
    ]
  },
  { path: '/login', name: 'login', component: Login },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/about', name: 'about', component: About },
  { path: '/contact', name: 'contact', component: Contact },
  { path: '/changepassword', name: 'changepassword', component: ChangePassword },

  { path: '/admin/screening-questions', name: 'Screening Questions', component: ScreeningQuestions },
  { path: '/admin/survey-questions', name: 'Survey Questions', component: SurveyQuestions },
  { path: '/admin/report', name: 'Report', component: Report },
  { path: '/admin/user-report', name: 'User Report', component: UserReport },
  { path: '/admin/about-us-management', name: 'About Us Management', component: AboutUsManagement },
  { path: '/admin/contact-us-management', name: 'Contact Us Management', component: ContactUsManagement },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView();
  }
})

export default router
